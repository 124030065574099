import React from "react";
import { connect } from "react-redux";
import { delayedDispatch, setBreadcrumb, setLoader, setTitle, updateCrumb } from "store/actions";
import { Props } from "./types";
import { Helmet } from "react-helmet";
import { push, replace } from "connected-react-router";
import { ContentWrapper, Icon, PhoneInput, Table } from "components";
import { Col, Input, notification, Row, Modal, Drawer, Select, Switch } from "antd";
import Compressor from "compressorjs";
import Dropzone from "react-dropzone";
import debounce from "lodash/debounce";
import { API, Endpoints } from "utils/api";
import Strings from "utils/strings";
import { formatPrice } from "utils/utils";
import { DateTime } from "luxon";
import "./styles.scss";

class UserDetail extends React.Component<Props, any> {
	phoneValidationInput: any;
	constructor(props: Props) {
		super(props);

		this.state = {
			name: "",
			email: "",
			phone: "",
			addresses: [],
			vatNumber: "",
			iban: "",
			associatedPartner: undefined,

			partnerOptions: [],
			searchParams: "",

			hasUnsavedFields: false,
			isNew: props.match.params.id === "new"
		};

		this.onDrop = this.onDrop.bind(this);
	}

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(""));

		this.getData();
		this.breadcrumb();
	}

	componentDidUpdate() {
		const { dispatch } = this.props;

		dispatch(setTitle(""));
		dispatch(updateCrumb());
	}

	breadcrumb() {
		const { dispatch } = this.props;

		dispatch(setBreadcrumb(null));
		delayedDispatch(
			setBreadcrumb(() => {
				const { isNew } = this.state;
				const { partners } = this.props;

				const actions = [] as any;
				if (!partners) {
					actions.push(
						{
							type: "button",
							text: Strings.generic.forget,
							disabled: this.state.deleted,
							separator: "right",
							isDelete: true,
							hasIcon: true,
							className: "BreadcrumbForgetButton",
							visible: !isNew,
							onClick: () => this.setState({ openConfirmForgetModal: true })
						},
						{
							type: "button",
							text: Strings.generic.save,
							disabled: !this.state.hasUnsavedFields,
							isSave: true,
							hasIcon: true,
							onClick: () => this.saveUser()
						}
					);
				} else if (partners && isNew) {
					actions.push({
						type: "button",
						text: Strings.generic.save,
						disabled: !this.state.hasUnsavedFields,
						isSave: true,
						hasIcon: true,
						onClick: () => this.saveUser()
					});
				}

				return {
					locations: [
						{
							icon: "user",
							text: Strings.sidebar.users,
							route: partners ? "/partner-clients" : "/clients"
						},
						{
							icon: this.state.isNew ? "plus" : "user",
							text: this.state.user?.name || Strings.users.addUser
						}
					],
					actions
				};
			})
		);
	}

	debounceFetcher = debounce(async (value: string) => {
		const body = { search: value, page: 0, perPage: 10 };

		const res = await API.post({ url: Endpoints.uriPartners("search"), data: body });
		if (res?.ok) {
			const { users } = res.data.results;
			this.setState({ partnerOptions: users });
		}
	}, 800);

	handleSearch = (value: string) => {
		if (value) {
			this.debounceFetcher(value);
			this.setState({ searchParams: value });
		} else {
			this.setState({ partnerOptions: [], searchParams: "" });
		}
	};

	async getData() {
		const { isNew } = this.state;
		const { dispatch, match, countries = [] } = this.props;

		dispatch(setLoader(true));

		try {
			if (!isNew) {
				let defaultCountries,
					user,
					defaultStates = {} as any,
					partnerOptions = [] as any;

				const promises = [API.get({ url: Endpoints.uriUsers(match?.params?.id) })];

				if (countries.length === 0) {
					promises.push(API.get({ url: Endpoints.uriCountries() }));
				} else {
					defaultCountries = JSON.parse(JSON.stringify(countries));
					defaultCountries.forEach((country: any) => (defaultStates[country._id] = country?.states || []));
				}

				const [response, responseCountries] = await Promise.all(promises);

				if (response?.ok) {
					user = response?.data?.results?.user || {};

					if (user.associatedPartner) {
						partnerOptions.push(user.associatedPartner);
						user.associatedPartner = user.associatedPartner._id;
					}

					dispatch(setTitle(user?.name || ""));
				} else {
					dispatch(push("/clients"));
					notification.error({
						message: Strings.users.title,
						description: response.data?.message || Strings.serverErrors.wentWrong,
						placement: "bottomRight",
						duration: 5
					});
				}

				if (responseCountries?.ok) {
					defaultCountries = responseCountries?.data?.results?.countries || [];
					defaultCountries.forEach((country: any) => (defaultStates[country._id] = country?.states || []));
				} else if (responseCountries) {
					notification.error({
						message: Strings.users.title,
						description: response.data?.message || Strings.serverErrors.wentWrong,
						placement: "bottomRight",
						duration: 5
					});
				}

				this.setState({
					...user,
					user,
					defaultCountries,
					defaultStates,
					partnerOptions
				});
			} else {
				if (countries.length > 0) return;

				const response = await API.get({ url: Endpoints.uriCountries() });

				if (response?.ok) {
					const { countries } = response?.data?.results || [];
					const defaultStates = [] as any;
					countries.forEach((country: any) => (defaultStates[country._id] = country?.states || []));
					this.setState({ defaultCountries: countries, defaultStates });
				} else {
					notification.error({
						message: Strings.users.title,
						description: response.data?.message || Strings.serverErrors.wentWrong,
						placement: "bottomRight",
						duration: 5
					});
				}
			}
		} catch (err) {
			dispatch(push("/clients"));
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		} finally {
			dispatch(setLoader(false));
		}
	}

	async saveUser() {
		const { isNew, photo, name, email, phone, addresses, vatNumber, country, state, postalCode, associatedPartner } = this.state;
		const { dispatch, match } = this.props;

		if (!this.validUser()) return;

		dispatch(setLoader(true));

		try {
			const body = new FormData();
			body.append("name", name);
			body.append("email", email);
			body.append("phone", phone);
			body.append("addresses", JSON.stringify(addresses));
			body.append("vatNumber", vatNumber);
			body.append("country", country);
			body.append("state", state);
			body.append("postalCode", postalCode);
			body.append("role", "user");

			if (associatedPartner) body.append("associatedPartner", associatedPartner);
			else body.append("associatedPartner", "");

			if (photo && Object.keys(photo).length > 0) {
				if (photo.file) {
					body.append("photo", photo.file);
				} else {
					body.append("photo", photo);
				}
			}

			const request = isNew ? API.post : API.put;
			const response = await request({
				url: Endpoints.uriUsers(isNew ? "" : match?.params?.id),
				data: body
			});

			if (response.ok) {
				const { user } = response.data.results || {};
				notification.success({
					message: Strings.users.title,
					description: response.data?.message,
					placement: "bottomRight",
					duration: 5
				});

				if (isNew) {
					dispatch(replace(`/clients/${user._id}`));
					this.breadcrumb();
				}

				this.setState({ ...user, user, isNew: false, hasUnsavedFields: false });
			} else {
				notification.error({
					message: Strings.users.title,
					description: response.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			console.log("error", err as string);
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async saveAddress() {
		const { selectedAddress } = this.state;
		const { dispatch, match } = this.props;

		if (!this.validAddress()) return;

		dispatch(setLoader(true));

		try {
			const body = { ...selectedAddress };
			delete body._id;

			const request = selectedAddress?._id ? API.put : API.post;
			const response = await request({
				url: Endpoints.uriUsers(`${match?.params?.id}/addresses/${selectedAddress?._id || ""}`),
				data: body
			});

			if (response.ok) {
				const { user } = response.data.results || {};
				this.setState({ ...user, user, selectedAddress: null, showAddressDrawer: false });

				notification.success({
					message: Strings.users.addresses,
					description: response.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.users.addresses,
					description: response.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async deleteAddress(id: string) {
		const { dispatch } = this.props;
		const { user } = this.state;

		dispatch(setLoader(true));

		try {
			const response = await API.delete({
				url: Endpoints.uriUsers(`${user._id}/addresses/${id}`)
			});

			if (response.ok) {
				const { user } = response.data.results || {};
				this.setState({ user, ...user });

				notification.success({
					message: Strings.users.addresses,
					description: response.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.users.addresses,
					description: (response.data?.message as string) || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err: unknown) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async forgetUser() {
		const { dispatch } = this.props;
		const { user } = this.state;

		dispatch(setLoader(true));

		const response = await API.patch({
			url: Endpoints.uriUsers(`${user._id}/forget`)
		});

		if (response.ok) {
			const { user } = response.data.results || {};
			this.setState({ openConfirmForgetModal: false, user, name: user.name, email: user.email });

			notification.success({
				message: Strings.users.addresses,
				description: response.data?.message,
				placement: "bottomRight",
				duration: 5
			});
		} else {
			notification.error({
				message: Strings.sidebar.users,
				description: (response.data?.message as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	validAddress() {
		const { selectedAddress } = this.state;

		if (!selectedAddress?.address) {
			notification.error({
				message: Strings.users.addresses,
				description: Strings.users.addressRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (!selectedAddress?.city) {
			notification.error({
				message: Strings.users.addresses,
				description: Strings.users.cityRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (!selectedAddress?.country) {
			notification.error({
				message: Strings.users.addresses,
				description: Strings.users.countryRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (!selectedAddress?.state) {
			notification.error({
				message: Strings.users.addresses,
				description: Strings.users.stateRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (!selectedAddress?.postalCode) {
			notification.error({
				message: Strings.users.addresses,
				description: Strings.users.zipRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		return true;
	}

	validUser() {
		const { name, phone, email, iban } = this.state;

		if (!name) {
			notification.error({
				message: Strings.users.addresses,
				description: Strings.users.nameRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (!phone) {
			notification.error({
				message: Strings.users.addresses,
				description: Strings.users.phoneRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (!email) {
			notification.error({
				message: Strings.users.addresses,
				description: Strings.users.emailRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (!this.validEmail(email)) {
			notification.error({
				message: Strings.users.addresses,
				description: Strings.users.emailInvalid,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		if (this.validIban(iban)) {
			notification.error({
				message: Strings.users.addresses,
				description: Strings.users.ibanInvalid,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		return true;
	}

	validIban(iban: string) {
		if (!iban) return false;

		const ibanRegex = /^[A-Z]{2}[0-9]{2}[A-Z0-9]{4}[0-9]{7}([A-Z0-9]?){0,16}$/;
		return !ibanRegex.test(iban);
	}

	validEmail(email: string) {
		if (!email) return false;

		const emailRegex =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return emailRegex.test(email);
	}

	getBase64(file: File) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	}

	onDrop(files: File[] | Blob[]) {
		try {
			const file = files.pop();

			new Compressor(file!, {
				quality: 0.9,
				maxWidth: 400,
				mimeType: "image/jpeg",
				success: (result: any) => {
					this.getBase64(result).then((res) => {
						this.setState({
							photo: { file: result, preview: res },
							hasUnsavedFields: true
						});
					});
				}
			});
		} catch (err) {
			console.log("err", err);
			notification.warn({
				message: Strings.errors.unsupportedFile,
				description: Strings.errors.fileNotSupported,
				placement: "bottomRight",
				duration: 5
			});
		}
	}

	getCountry(id: string) {
		const { defaultCountries } = this.state;
		return defaultCountries.find((c: any) => c._id === id)?.name;
	}

	getState(id: string, countryId: string) {
		const { defaultStates } = this.state;
		const states = defaultStates?.[countryId] || {};
		return states.find((c: any) => c._id === id)?.name;
	}

	getPaymentStatus(status: string) {
		const statusString = status as keyof typeof Strings.orders.paymentStatuses;
		return Strings.orders.paymentStatuses[statusString] || "-";
	}

	getShippingStatus(status: string) {
		const statusString = status as keyof typeof Strings.orders.shippingStatuses;
		return Strings.orders.shippingStatuses[statusString] || "-";
	}

	getOrderStatus = (closed: boolean) => {
		if (closed) {
			return (
				<div className="TableTag --tag-success">
					<span>{Strings.orders.closed}</span>
				</div>
			);
		}

		return (
			<div className="TableTag --tag-warning">
				<span>{Strings.orders.pending}</span>
			</div>
		);
	};

	renderConfirmForget = () => {
		const { openConfirmForgetModal } = this.state;

		return (
			<Modal
				centered
				className="confirmForget"
				style={{ textAlign: "center" }}
				visible={openConfirmForgetModal}
				cancelText={Strings.generic.close}
				okText={Strings.authentication.forgetConfirm}
				onOk={() => this.forgetUser()}
				onCancel={() => this.setState({ openConfirmForgetModal: false })}
				title={null}
				closable={false}
				bodyStyle={{ minHeight: 420 }}
			>
				<Icon name="user" style={{ fontSize: "50px" }}></Icon>
				<div className="title">
					{openConfirmForgetModal?.email
						? Strings.formatString(Strings.authentication.forgetTitle, openConfirmForgetModal?.email)
						: Strings.authentication.forgetTitle}
				</div>
			</Modal>
		);
	};

	renderUserInformation() {
		const { name, email, photo, phone, vatNumber, iban, isNew, partnerOptions, associatedPartner, searchParams, userCode } = this.state;

		return (
			<ContentWrapper>
				<div className="ScreenHeader">
					<div className="ScreenHeaderLeft">
						<Icon name="user" />
						<h2>{Strings.users.fullInfo}</h2>
					</div>
				</div>
				<div className="UserContainer">
					<div className="UserAvatarContainer">
						<Dropzone accept="image/jpg, image/jpeg, image/png" className="UserAvatarInnerContainer" onDrop={this.onDrop}>
							<div className="AvatarWrapper">
								{photo ? (
									<img src={photo?.preview || photo} alt="client avatar" />
								) : (
									<div className="UserAvatarPlaceholder">
										<Icon name="frame" />
										<p>{Strings.users.noImage}</p>
									</div>
								)}
							</div>
						</Dropzone>
					</div>
					<div className="UserInformationWrapper">
						<Row gutter={[20, 10]}>
							<Col xs={24}>
								<label htmlFor="name" className="InputLabel --label-required">
									{Strings.fields.name}
								</label>
								<Input
									id="name"
									placeholder={Strings.fields.name}
									style={{ height: 40 }}
									value={name || ""}
									onChange={(e) => this.setState({ name: e.target.value, hasUnsavedFields: true })}
								/>
							</Col>
							<Col xs={12}>
								<label htmlFor="email" className="InputLabel --label-required">
									{Strings.fields.email}
								</label>
								<Input
									id="email"
									type="email"
									placeholder={Strings.fields.email}
									style={{ height: 40 }}
									value={email || ""}
									readOnly={!isNew}
									onChange={(e) => this.setState({ email: e.target.value, hasUnsavedFields: true })}
								/>
							</Col>
							<Col xs={12}>
								<label htmlFor="partner_code" className="InputLabel">
									{Strings.users.userCode}
								</label>
								<Input id="partner_code" placeholder={Strings.users.userCode} value={userCode || ""} readOnly />
							</Col>
							<Col xs={12}>
								<label htmlFor="phone" className="InputLabel --label-required">
									{Strings.fields.phone}
								</label>
								<PhoneInput
									ref={(ref: any) => {
										this.phoneValidationInput = ref;
									}}
									defaultCountry="pt"
									style={{ height: 40 }}
									value={phone || ""}
									inputClass="input-phone"
									inputProps={{
										name: "phone",
										required: true,
										autoComplete: "off",
										placeholder: Strings.fields.phone
									}}
									onChange={(phone: any) => this.setState({ phone, hasUnsavedFields: true })}
								/>
							</Col>
							<Col xs={12}>
								<label htmlFor="vat_number" className="InputLabel">
									{Strings.fields.vatNumber}
								</label>
								<Input
									id="var_number"
									placeholder={Strings.fields.vatNumber}
									style={{ height: 40 }}
									value={vatNumber || ""}
									onChange={(e) => this.setState({ vatNumber: e.target.value, hasUnsavedFields: true })}
								/>
							</Col>
							<Col xs={12}>
								<label htmlFor="iban" className="InputLabel">
									{Strings.fields.iban}
								</label>
								<Input
									id="iban"
									placeholder={Strings.fields.iban}
									style={{ height: 40 }}
									value={iban || ""}
									pattern="[A-Z]{2}[0-9]{2}[A-Z0-9]{4}[0-9]{7}([A-Z0-9]?){0,16}"
									onChange={(e) => this.setState({ iban: e.target.value?.toUpperCase(), hasUnsavedFields: true })}
								/>
							</Col>
							<Col xs={12}>
								<label htmlFor="partner" className="InputLabel">
									{Strings.staff.partner}
								</label>
								<Select
									id="partner"
									showSearch
									filterOption={false}
									onSearch={this.handleSearch}
									onChange={(value) => {
										this.setState({ associatedPartner: value, hasUnsavedFields: true });
									}}
									onClear={() =>
										this.setState({
											associatedPartner: undefined,
											partnerOptions: [],
											searchParams: "",
											hasUnsavedFields: true
										})
									}
									value={associatedPartner}
									placeholder={Strings.staff.partner}
									style={{ width: "100%" }}
									notFoundContent={
										<div>{searchParams === "" ? Strings.generic.searchByEmailOrName : Strings.generic.noResults}</div>
									}
									allowClear
								>
									{partnerOptions?.map((partner: any) => (
										<Select.Option value={partner._id} key={partner._id}>
											{partner.name} ({partner.email})
										</Select.Option>
									))}
								</Select>
							</Col>
						</Row>
					</div>
				</div>
			</ContentWrapper>
		);
	}

	renderUserAddresses() {
		const { addresses } = this.state;
		const { partners } = this.props;

		let actions, add;
		if (!partners) {
			actions = {
				edit: (obj: any) => ({
					onClick: () => this.setState({ showAddressDrawer: true, selectedAddress: JSON.parse(JSON.stringify(obj)) })
				}),
				remove: (obj: any) => ({
					onClick: () => this.deleteAddress(obj._id)
				})
			};

			add = {
				tooltip: Strings.users.addAddress,
				onClick: () => this.setState({ showAddressDrawer: true, selectedAddress: null })
			};
		}

		return (
			<Table
				style={{ marginBottom: 10 }}
				title={{
					icon: "directions",
					title: Strings.users.addresses
				}}
				data={addresses}
				columns={[
					{
						Header: Strings.fields.name,
						id: "name",
						accessor: (row: any) => row.name
					},
					{
						Header: Strings.fields.address,
						id: "address",
						accessor: (row: any) => row.address
					},
					{
						Header: Strings.fields.country,
						id: "country",
						accessor: (row: any) => (row.country ? this.getCountry(row.country) : "-")
					},
					{
						Header: Strings.fields.state,
						id: "state",
						accessor: (row: any) => (row.state ? this.getState(row.state, row.country) : "-")
					},
					{
						Header: Strings.users.isDefault,
						id: "isDefault",
						accessor: (user: any) => user.isDefault
					}
				]}
				add={add}
				actions={actions}
			/>
		);
	}

	renderOrders() {
		const { orders } = this.state;
		const { partners, dispatch } = this.props;

		let actions;
		if (!partners) {
			actions = {
				view: (obj: any) => ({
					onClick: () => dispatch(push(`/orders/${obj._id}`))
				})
			};
		} else {
			actions = {
				view: (obj: any) => ({
					onClick: () => dispatch(push(`/partner-orders/${obj._id}`))
				})
			};
		}

		return (
			<Table
				style={{ marginBottom: 20 }}
				title={{
					icon: "box",
					title: Strings.sidebar.orders
				}}
				data={orders}
				columns={[
					{
						Header: Strings.fields.date,
						id: "_created",
						accessor: (row: any) => (row._created ? DateTime.fromISO(row._created).toFormat("dd/MM/yyyy") : "-"),
						maxWidth: 100,
						Filter: () => null
					},
					{
						Header: Strings.orders.orderNumber,
						id: "orderNumber",
						accessor: (row: any) => row.orderNumber
					},
					{
						Header: Strings.orders.paymentStatus,
						id: "paymentStatus",
						accessor: (row: any) => this.getPaymentStatus(row.paymentStatus),
						Filter: () => null
					},
					{
						Header: Strings.orders.shippingStatus,
						id: "shippingStatus",
						accessor: (row: any) => this.getShippingStatus(row.shippingStatus),
						Filter: () => null
					},
					{
						Header: Strings.orders.status,
						id: "isClosed",
						Cell: (row: any) => this.getOrderStatus(row.cell.row.original.isClosed),
						maxWidth: 150
					},
					{
						Header: Strings.fields.total,
						id: "total",
						accessor: (row: any) => (row.total != null ? formatPrice(row.total) : "-"),
						align: "right",
						maxWidth: 100
					}
				]}
				actions={actions}
			/>
		);
	}

	renderAddressDrawer() {
		const { showAddressDrawer, selectedAddress } = this.state;
		const { mobile } = this.props;

		return (
			<Drawer
				title={
					<div className="SidebarTitleContainer">
						<Icon name="directions" />
						<p>{selectedAddress?._id ? Strings.users.editAddress : Strings.users.addAddress}</p>
					</div>
				}
				footer={
					<div className="SidebarFooterContainer">
						<button type="button" className="SidebarFooterButton --button-confirm" onClick={() => this.saveAddress()}>
							{Strings.generic.confirm}
						</button>
						<button
							type="button"
							className="SidebarFooterButton --button-cancel"
							onClick={() => this.setState({ showAddressDrawer: false, tempAddress: null })}
						>
							{Strings.generic.cancel}
						</button>
					</div>
				}
				placement="right"
				closable={false}
				onClose={() => this.setState({ showAddressDrawer: false, tempAddress: null })}
				visible={showAddressDrawer}
				width={mobile ? "100%" : 400}
			>
				{this.renderDrawerContent()}
			</Drawer>
		);
	}

	renderDrawerContent() {
		const { selectedAddress, defaultCountries = [], defaultStates = [] } = this.state;

		return (
			<Row gutter={[20, 10]}>
				<Col xs={24}>
					<label htmlFor="name" className="InputLabel --label-required">
						{Strings.fields.name}
					</label>
					<Input
						id="name"
						placeholder={Strings.fields.name}
						value={selectedAddress?.name || ""}
						onChange={(e) => this.setState({ selectedAddress: { ...selectedAddress, name: e.target.value } })}
					/>
				</Col>
				<Col xs={24}>
					<label htmlFor="phone" className="InputLabel">
						{Strings.fields.phone}
					</label>
					<PhoneInput
						ref={(ref: any) => {
							this.phoneValidationInput = ref;
						}}
						defaultCountry="pt"
						value={selectedAddress?.phone || ""}
						inputClass="input-phone"
						inputProps={{
							name: "phone",
							required: true,
							autoComplete: "off",
							placeholder: Strings.fields.phone
						}}
						onChange={(phone: string) => this.setState({ selectedAddress: { ...selectedAddress, phone } })}
					/>
				</Col>
				<Col xs={24}>
					<label htmlFor="address" className="InputLabel --label-required">
						{Strings.fields.address}
					</label>
					<Input
						id="address"
						placeholder={Strings.fields.address}
						value={selectedAddress?.address || ""}
						onChange={(e) => this.setState({ selectedAddress: { ...selectedAddress, address: e.target.value } })}
					/>
				</Col>
				<Col xs={24}>
					<label htmlFor="address2" className="InputLabel">
						{Strings.fields.address2}
					</label>
					<Input
						id="address2"
						placeholder={Strings.fields.address2}
						value={selectedAddress?.address2 || ""}
						onChange={(e) => this.setState({ selectedAddress: { ...selectedAddress, address2: e.target.value } })}
					/>
				</Col>
				<Col xs={24}>
					<label htmlFor="zip" className="InputLabel --label-required">
						{Strings.fields.zipCode}
					</label>
					<Input
						id="zip"
						placeholder={Strings.fields.zipCode}
						value={selectedAddress?.postalCode || ""}
						onChange={(e) => this.setState({ selectedAddress: { ...selectedAddress, postalCode: e.target.value } })}
					/>
				</Col>
				<Col xs={24}>
					<label htmlFor="city" className="InputLabel --label-required">
						{Strings.fields.city}
					</label>
					<Input
						id="city"
						placeholder={Strings.fields.city}
						value={selectedAddress?.city || ""}
						onChange={(e) => this.setState({ selectedAddress: { ...selectedAddress, city: e.target.value } })}
					/>
				</Col>
				<Col xs={24}>
					<label htmlFor="country" className="InputLabel --label-required">
						{Strings.fields.country}
					</label>
					<Select
						style={{ width: "100%" }}
						placeholder={Strings.fields.country}
						showSearch
						filterOption={(input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						value={selectedAddress?.country || null}
						onChange={(elem: any) => {
							this.setState((prevState: any) => ({
								selectedAddress: {
									...prevState.selectedAddress,
									country: elem,
									state: null,
									hasUnsavedFields: true
								}
							}));
						}}
					>
						{defaultCountries.map((country: any) => (
							<Select.Option key={country._id} value={country._id}>
								{country?.name}
							</Select.Option>
						))}
					</Select>
				</Col>
				<Col xs={24}>
					<label htmlFor="state" className="InputLabel --label-required">
						{Strings.fields.state}
					</label>
					<Select
						id="state"
						style={{ width: "100%" }}
						placeholder={selectedAddress?.country ? Strings.fields.state : Strings.fields.selectCountryFirst}
						showSearch
						filterOption={(input: any, option: any) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						value={selectedAddress?.state || null}
						disabled={!selectedAddress?.country}
						options={
							selectedAddress?.country
								? defaultStates[selectedAddress?.country].map((state: any) => ({
										value: state?._id,
										label: state?.name
								  }))
								: null
						}
						onChange={(elem: any) => {
							this.setState((prevState: any) => ({
								selectedAddress: {
									...prevState.selectedAddress,
									state: elem,
									hasUnsavedFields: true
								}
							}));
						}}
					/>
				</Col>
				<Col xs={24}>
					<label htmlFor="vat_number" className="InputLabel">
						{Strings.fields.vatNumber}
					</label>
					<Input
						id="vat_number"
						placeholder={Strings.fields.vatNumber}
						value={selectedAddress?.vatNumber || ""}
						onChange={(e) => this.setState({ selectedAddress: { ...selectedAddress, vatNumber: e.target.value } })}
					/>
				</Col>
				<Col xs={24}>
					<div className={`General_ColorFul_Switch ${selectedAddress?.isDefault ? "__active" : ""}`}>
						<span>{Strings.users.isDefault}</span>
						<Switch
							className={`Switch ${selectedAddress?.isDefault ? "__active" : ""}`}
							checked={selectedAddress?.isDefault || false}
							size="small"
							onChange={(value: any) =>
								this.setState((prevState: any) => ({
									selectedAddress: {
										...prevState.selectedAddress,
										isDefault: value,
										hasUnsavedFields: true
									}
								}))
							}
						/>
					</div>
				</Col>
			</Row>
		);
	}

	render() {
		const { isNew } = this.state;

		return (
			<div className="UserDetailScreen">
				<Helmet>
					<title>{Strings.users.header}</title>
					<meta name="description" content="Edit your user\'s information" />
				</Helmet>
				{this.renderUserInformation()}
				{!isNew && (
					<>
						{this.renderUserAddresses()}
						{this.renderOrders()}
					</>
				)}
				{this.renderConfirmForget()}
				{this.renderAddressDrawer()}
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
	mobile: state.mobile,
	countries: state.countries
});

export default connect(mapStateToProps)(UserDetail);
